



























import { Component, Vue } from 'vue-property-decorator'
import { getArticleDetail, getrecommendArticle } from '@/api/news'
@Component({
  components: {}
})
export default class NewsDetails extends Vue {
  ArticleDetail = {}
  recommend = []
  created() {
    getArticleDetail(this.$route.query.id).then((res) => {
      this.ArticleDetail = res.data
    })
    getrecommendArticle().then((res) => {
      this.recommend = res.data
    })
  }
  Jump(id: any) {
    getArticleDetail(id).then((res) => {
      this.ArticleDetail = res.data
    })
  }
}
